``
<template>
  <v-dialog :value="showGisDataPointFilterDialog" max-width="500px" persistent>
    <validation-observer ref="filterForm" slim v-slot="{ valid: isFormValid }">
      <v-card>
        <v-toolbar dark color="#3F51B5" class="elevation-0">
          <v-toolbar-title>Filter</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="$emit(`gis-data-point-filter-close`)">
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="pt-3">
          <div>Filter</div>
          <v-card class="mb-5 px-0" elevation="0">
            <v-card-text class="px-0 mx-0">
              <div class="d-flex flex-wrap gap">
                <v-chip
                  v-for="(f, i) of filterChoices"
                  :key="f.selectedField"
                  close
                  @click:close="removeFilter(i)"
                >
                  <div class="chip overflow-hidden text-truncate my-0 py-0">
                    {{ f.selectedField | capitalize }}
                    <MatchIcon :value="f.selectedMatchChoice" class="mx-2" />
                    <template v-if="Array.isArray(f.selectedMatchValue)">
                      {{ f.selectedMatchValue.join(", ") }}
                    </template>
                    <template v-else>
                      {{ f.selectedMatchValue }}
                    </template>
                  </div>
                </v-chip>
              </div>
            </v-card-text>
          </v-card>

          <div>Add to Filter</div>
          <v-card id="addToFilterPanel">
            <v-card-text>
              <form
                class="align-center justify-space-between gap"
                :class="{ 'd-flex': $vuetify.breakpoint.smAndUp }"
              >
                <v-select
                  :items="fieldChoices"
                  v-model="selectedField"
                  item-text="label"
                  item-value="value"
                  label="Field"
                  class="flex-grow-1"
                  :style="{
                    width: $vuetify.breakpoint.smAndUp ? '100%' : undefined,
                  }"
                  hide-details
                >
                  <template #item="{ item: { label } }">
                    <div class="filter-choice">
                      {{ label }}
                    </div>
                  </template>

                  <template #selection="{ item: { label } }">
                    <div
                      class="text-truncate"
                      :style="{
                        width: $vuetify.breakpoint.xsOnly ? '100%' : '100px',
                      }"
                    >
                      {{ label }}
                    </div>
                  </template>
                </v-select>

                <validation-provider :rules="{ required: true }">
                  <v-select
                    :items="filteredMatchChoices"
                    v-model="selectedMatchChoice"
                    item-text="label"
                    item-value="value"
                    flat
                    hide-details
                    :class="{ 'field-select': $vuetify.breakpoint.smAndUp }"
                  >
                    >
                    <template #item="{ item: { label, value } }">
                      <div class="filter-choice">
                        <MatchIcon :value="value" />
                        {{ label }}
                      </div>
                    </template>

                    <template #selection="{ item: { value } }">
                      <div class="filter-selection text-truncate">
                        <MatchIcon :value="value" />
                      </div>
                    </template>
                  </v-select>
                </validation-provider>

                <div
                  :style="{
                    width: $vuetify.breakpoint.smAndUp ? '100%' : undefined,
                  }"
                >
                  <v-menu
                    v-if="
                      [
                        FIELD_TYPES.DATE,
                        FIELD_TYPES.ESRI_FIELD_TYPE_DATE,
                      ].includes(fieldType)
                    "
                    :close-on-click="false"
                    :close-on-content-click="false"
                    v-model="openFormFieldDatePicker"
                  >
                    <template v-slot:activator="{ on }">
                      <validation-provider
                        :rules="{ required: true }"
                        v-slot="{ errors, valid }"
                        name="Value"
                      >
                        <v-text-field
                          v-on="on"
                          v-model="selectedMatchValue"
                          class="flex-grow-1"
                          label="Value"
                          hide-details
                          clearable
                          :error-messages="errors"
                          :success="valid"
                        >
                          <template #append>
                            <v-btn
                              small
                              icon
                              @click="
                                on.click($event);
                                openFormFieldDatePicker = true;
                              "
                            >
                              <v-icon color="#3F51B5">
                                {{ mdiCalendar }}
                              </v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-if="openFormFieldDatePicker"
                      v-model="selectedMatchValue"
                      :range="selectedMatchChoice === MATCH_CHOICE_VALUES.RANGE"
                      @click:date="onFormFieldDateClick()"
                      v-click-outside="onFormFieldDatePickerClickOutside"
                      :width="300"
                    >
                    </v-date-picker>
                  </v-menu>

                  <validation-provider
                    :rules="{ required: true, regex: /^\d+$/ }"
                    v-else-if="fieldType === FIELD_TYPES.NUMBER"
                  >
                    <v-text-field
                      v-model="selectedMatchValue"
                      type="number"
                      class="flex-grow-1"
                      label="Value"
                      hide-details
                    >
                    </v-text-field>
                  </validation-provider>

                  <template v-else>
                    <validation-provider :rules="{ required: true }">
                      <v-autocomplete
                        v-if="valueChoices.length > 0"
                        v-model="selectedMatchValue"
                        :items="valueChoices"
                        item-text="label"
                        item-value="value"
                        class="flex-grow-1"
                        label="Value"
                        hide-details
                      >
                      </v-autocomplete>
                      <v-text-field
                        v-else
                        v-model="selectedMatchValue"
                        class="flex-grow-1"
                        label="Value"
                        hide-details
                      >
                      </v-text-field>
                    </validation-provider>
                  </template>
                </div>

                <v-btn
                  icon
                  color="#3F51B5"
                  @click="addToFilter"
                  :disabled="!isFormValid"
                  id="addFilter"
                >
                  <v-icon>
                    {{ mdiFilterPlus }}
                  </v-icon>
                </v-btn>
              </form>
            </v-card-text>
          </v-card>
        </v-card-text>

        <v-card-actions class="d-flex justify-end px-5 py-3">
          <v-btn
            elevation="2"
            color="#3F51B5"
            @click="updateFilter"
            :disabled="!filterChanged"
            :dark="filterChanged"
            id="updateFilterButton"
          >
            Update Filter
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
  mdiFilterPlus,
  mdiCalendar,
  mdiCalendarArrowLeft,
  mdiCalendarArrowRight,
} from "@mdi/js";
import MATCH_CHOICE_VALUES from "@/constants/matchChoiceValues";
import FIELD_TYPES from "@/constants/fieldTypes";
import { cloneDeep } from "lodash";
import MatchIcon from "@/components/list/gis-data-point-filter-dialog/MatchIcon";
import { db } from "@/mixins/utilisync-db";
import { axiosWithNoAuth } from "@/plugins/axios";
import moment from "moment";

export default {
  name: "GisDataPointFilterDialog",
  props: {
    showGisDataPointFilterDialog: Boolean,
    gisDataPoints: Array,
    savedFilterChoices: Array,
    selectedMapServiceId: String,
    featureItemFields: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    MatchIcon,
  },
  data() {
    return {
      mdiClose,
      mdiFilterPlus,
      mdiCalendar,
      mdiCalendarArrowLeft,
      mdiCalendarArrowRight,
      MATCH_CHOICE_VALUES,
      selectedField: undefined,
      selectedMatchChoice: MATCH_CHOICE_VALUES.EQUALS,
      selectedMatchValue: undefined,
      filterChoices: [],
      filterChanged: false,
      FIELD_TYPES,
      filterFormValid: false,
      gisDataFields: [],
      esriFields: [],
      openFormFieldDatePicker: false,
    };
  },
  computed: {
    fieldType() {
      const gisDataField = this.gisDataFields.find(
        (g) =>
          g.name?.replaceAll(" ", "")?.toLowerCase() ===
          this.selectedField?.replaceAll(" ", "")?.toLowerCase()
      );
      const esriField =
        this.esriFields.find(
          (g) =>
            g.name?.replaceAll(" ", "")?.toLowerCase() ===
              this.selectedField?.replaceAll(" ", "")?.toLowerCase() ||
            g.alias?.replaceAll(" ", "")?.toLowerCase() ===
              this.selectedField?.replaceAll(" ", "")?.toLowerCase()
        ) ?? {};

      const field = gisDataField ?? esriField ?? {};
      const { type } = field;
      return type;
    },
    filteredMatchChoices() {
      const { fieldType, valueChoices } = this;
      let doesNotEqualChoice = [];
      if (valueChoices.length > 0) {
        doesNotEqualChoice = [
          {
            label: "Does Not Equal",
            value: MATCH_CHOICE_VALUES.DOES_NOT_EQUAL,
          },
        ];
      }
      if (
        [
          FIELD_TYPES.STRING,
          FIELD_TYPES.ESRI_FIELD_TYPE_GUID,
          FIELD_TYPES.ESRI_FIELD_TYPE_GLOBAL_ID,
          FIELD_TYPES.ESRI_FIELD_TYPE_XML,
        ].includes(fieldType)
      ) {
        return [
          { label: "Equals", value: MATCH_CHOICE_VALUES.EQUALS },
          { label: "Contains", value: MATCH_CHOICE_VALUES.CONTAINS },
          ...doesNotEqualChoice,
        ];
      } else if (
        [
          FIELD_TYPES.NUMBER,
          FIELD_TYPES.ESRI_FIELD_TYPE_OID,
          FIELD_TYPES.ESRI_FIELD_TYPE_INTEGER,
          FIELD_TYPES.ESRI_FIELD_TYPE_SINGLE,
          FIELD_TYPES.ESRI_FIELD_TYPE_DOUBLE,
        ].includes(fieldType)
      ) {
        return [
          { label: "Equals", value: MATCH_CHOICE_VALUES.EQUALS },
          { label: "Greater Than", value: MATCH_CHOICE_VALUES.GREATER_THAN },
          {
            label: "Greater Than or Equal",
            value: MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL,
          },
          { label: "Less Than", value: MATCH_CHOICE_VALUES.LESS_THAN },
          {
            label: "Less Than or Equal",
            value: MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL,
          },
          {
            label: "Does Not Equal",
            value: MATCH_CHOICE_VALUES.DOES_NOT_EQUAL,
          },
        ];
      } else if (
        [FIELD_TYPES.DATE, FIELD_TYPES.ESRI_FIELD_TYPE_DATE].includes(fieldType)
      ) {
        return [
          {
            label: "On or After",
            value: MATCH_CHOICE_VALUES.ON_OR_AFTER,
          },
          {
            label: "On or Before",
            value: MATCH_CHOICE_VALUES.ON_OR_BEFORE,
          },
          {
            label: "Range",
            value: MATCH_CHOICE_VALUES.RANGE,
          },
        ];
      }
      return [
        { label: "Equals", value: MATCH_CHOICE_VALUES.EQUALS },
        { label: "Contains", value: MATCH_CHOICE_VALUES.CONTAINS },
        ...doesNotEqualChoice,
      ];
    },
    fieldChoices() {
      const [gisDataPoint = {}] = this.gisDataPoints;
      const fields = Object.keys(gisDataPoint).filter(
        (f) => f !== "mapServiceId"
      );
      return fields.map((f) => {
        return {
          label: f,
          value: f,
        };
      });
    },
    valueChoices() {
      if (!this.selectedField) {
        return [];
      }
      const fieldValuesWithDups = this.gisDataPoints
        .map((g) => g[this.selectedField])
        .filter(Boolean);
      const esriField = this.esriFields.find(
        (g) =>
          g.name?.replaceAll(" ", "")?.toLowerCase() ===
          this.selectedField?.replaceAll(" ", "")?.toLowerCase()
      );

      let esriFieldValueChoices = [];
      if (Array.isArray(esriField?.domain?.codedValues)) {
        esriFieldValueChoices = [...esriField?.domain?.codedValues].map((c) => {
          const { name } = c;
          return {
            label: name,
            value: name,
          };
        });
      }

      const values = [...new Set(fieldValuesWithDups)];
      let gisDataFieldValueChoices = [];
      if (esriField?.type === FIELD_TYPES.ESRI_FIELD_TYPE_DATE) {
        gisDataFieldValueChoices = values.map((v) => {
          return {
            label: moment(new Date(v)).format("MM/DD/YYYY"),
            value: v,
          };
        });
      } else {
        gisDataFieldValueChoices = values.map((v) => {
          return {
            label: v,
            value: v,
          };
        });
      }
      return [...gisDataFieldValueChoices, ...esriFieldValueChoices];
    },
  },
  watch: {
    filteredMatchChoices: {
      deep: true,
      handler(val) {
        const [firstChoice] = val;
        this.selectedMatchChoice = firstChoice?.value;
      },
    },
  },
  methods: {
    getEsriFieldAlias(key) {
      const field = [...this.featureItemFields].find(
        (f) =>
          f.name?.replaceAll(" ", "")?.toLowerCase() ===
          key.replaceAll(" ", "")?.toLowerCase()
      );
      return field?.alias ?? key;
    },
    onFormFieldDateClick() {
      if (this.selectedMatchChoice === MATCH_CHOICE_VALUES.RANGE) {
        this.openFormFieldDatePicker =
          (this.selectedMatchValue?.length ?? 0) < 2;
      } else {
        this.openFormFieldDatePicker = false;
      }
    },
    onFormFieldDatePickerClickOutside() {
      this.openFormFieldDatePicker = false;
    },
    async getEsriFields() {
      if (navigator.onLine) {
        const [mapService] = await db.mapServices
          .filter((m) => m.map_service_id === this.selectedMapServiceId)
          .toArray();
        if (mapService?.service_type === "F") {
          const mapServiceUrl = mapService?.service_url;
          let queryResult = {};
          try {
            const { data } = await axiosWithNoAuth.get(
              `${mapServiceUrl}/query`,
              {
                params: {
                  where: "1=1",
                  outFields: "*",
                  f: "json",
                  token: localStorage.getItem("esri_token"),
                },
              }
            );
            queryResult = data;
            const { fields } = queryResult;
            this.esriFields = fields;
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
    async getGisDataFields() {
      this.gisDataFields = await db.gisDataFields.toCollection().toArray();
    },
    addToFilter() {
      const { selectedField, selectedMatchChoice, selectedMatchValue } = this;
      const filter = { selectedField, selectedMatchChoice, selectedMatchValue };
      const index = this.filterChoices.findIndex((fc) => {
        return fc.selectedField === selectedField;
      });
      if (index >= 0) {
        this.filterChoices.splice(index, 1);
      }
      this.filterChoices.push(filter);
      this.selectedField = undefined;
      this.selectedMatchChoice = MATCH_CHOICE_VALUES.EQUALS;
      this.selectedMatchValue = undefined;
      this.filterChanged = true;
    },
    updateFilter() {
      this.$emit("update-filter", this.filterChoices);
    },
    removeFilter(index) {
      this.filterChoices.splice(index, 1);
      this.filterChanged = true;
    },
  },
  beforeMount() {
    this.filterChoices = cloneDeep(this.savedFilterChoices);
    this.getGisDataFields();
    this.getEsriFields();
  },
};
</script>

<style scoped>
.field-select >>> .v-input__slot::before {
  border-style: none;
}

.filter-choice {
  width: 180px;
}

.filter-selection {
  width: 50px;
}
</style>
