var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showGisDataPointFilterDialog,
        "max-width": "500px",
        persistent: ""
      }
    },
    [
      _c("validation-observer", {
        ref: "filterForm",
        attrs: { slim: "" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var isFormValid = ref.valid
              return [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-toolbar",
                      {
                        staticClass: "elevation-0",
                        attrs: { dark: "", color: "#3F51B5" }
                      },
                      [
                        _c("v-toolbar-title", [_vm._v("Filter")]),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("gis-data-point-filter-close")
                              }
                            }
                          },
                          [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card-text",
                      { staticClass: "pt-3" },
                      [
                        _c("div", [_vm._v("Filter")]),
                        _c(
                          "v-card",
                          {
                            staticClass: "mb-5 px-0",
                            attrs: { elevation: "0" }
                          },
                          [
                            _c("v-card-text", { staticClass: "px-0 mx-0" }, [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-wrap gap" },
                                _vm._l(_vm.filterChoices, function(f, i) {
                                  return _c(
                                    "v-chip",
                                    {
                                      key: f.selectedField,
                                      attrs: { close: "" },
                                      on: {
                                        "click:close": function($event) {
                                          return _vm.removeFilter(i)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "chip overflow-hidden text-truncate my-0 py-0"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("capitalize")(
                                                  f.selectedField
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c("MatchIcon", {
                                            staticClass: "mx-2",
                                            attrs: {
                                              value: f.selectedMatchChoice
                                            }
                                          }),
                                          Array.isArray(f.selectedMatchValue)
                                            ? [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      f.selectedMatchValue.join(
                                                        ", "
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            : [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      f.selectedMatchValue
                                                    ) +
                                                    " "
                                                )
                                              ]
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ])
                          ],
                          1
                        ),
                        _c("div", [_vm._v("Add to Filter")]),
                        _c(
                          "v-card",
                          { attrs: { id: "addToFilterPanel" } },
                          [
                            _c("v-card-text", [
                              _c(
                                "form",
                                {
                                  staticClass:
                                    "align-center justify-space-between gap",
                                  class: {
                                    "d-flex": _vm.$vuetify.breakpoint.smAndUp
                                  }
                                },
                                [
                                  _c("v-select", {
                                    staticClass: "flex-grow-1",
                                    style: {
                                      width: _vm.$vuetify.breakpoint.smAndUp
                                        ? "100%"
                                        : undefined
                                    },
                                    attrs: {
                                      items: _vm.fieldChoices,
                                      "item-text": "label",
                                      "item-value": "value",
                                      label: "Field",
                                      "hide-details": ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function(ref) {
                                            var label = ref.item.label
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "filter-choice"
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(label) + " "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "selection",
                                          fn: function(ref) {
                                            var label = ref.item.label
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "text-truncate",
                                                  style: {
                                                    width: _vm.$vuetify
                                                      .breakpoint.xsOnly
                                                      ? "100%"
                                                      : "100px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(label) + " "
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.selectedField,
                                      callback: function($$v) {
                                        _vm.selectedField = $$v
                                      },
                                      expression: "selectedField"
                                    }
                                  }),
                                  _c(
                                    "validation-provider",
                                    { attrs: { rules: { required: true } } },
                                    [
                                      _c(
                                        "v-select",
                                        {
                                          class: {
                                            "field-select":
                                              _vm.$vuetify.breakpoint.smAndUp
                                          },
                                          attrs: {
                                            items: _vm.filteredMatchChoices,
                                            "item-text": "label",
                                            "item-value": "value",
                                            flat: "",
                                            "hide-details": ""
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "item",
                                                fn: function(ref) {
                                                  var ref_item = ref.item
                                                  var label = ref_item.label
                                                  var value = ref_item.value
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "filter-choice"
                                                      },
                                                      [
                                                        _c("MatchIcon", {
                                                          attrs: {
                                                            value: value
                                                          }
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(label) +
                                                            " "
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              },
                                              {
                                                key: "selection",
                                                fn: function(ref) {
                                                  var value = ref.item.value
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "filter-selection text-truncate"
                                                      },
                                                      [
                                                        _c("MatchIcon", {
                                                          attrs: {
                                                            value: value
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: _vm.selectedMatchChoice,
                                            callback: function($$v) {
                                              _vm.selectedMatchChoice = $$v
                                            },
                                            expression: "selectedMatchChoice"
                                          }
                                        },
                                        [_vm._v(" > ")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      style: {
                                        width: _vm.$vuetify.breakpoint.smAndUp
                                          ? "100%"
                                          : undefined
                                      }
                                    },
                                    [
                                      [
                                        _vm.FIELD_TYPES.DATE,
                                        _vm.FIELD_TYPES.ESRI_FIELD_TYPE_DATE
                                      ].includes(_vm.fieldType)
                                        ? _c(
                                            "v-menu",
                                            {
                                              attrs: {
                                                "close-on-click": false,
                                                "close-on-content-click": false
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function(ref) {
                                                      var on = ref.on
                                                      return [
                                                        _c(
                                                          "validation-provider",
                                                          {
                                                            attrs: {
                                                              rules: {
                                                                required: true
                                                              },
                                                              name: "Value"
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "default",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var errors =
                                                                      ref.errors
                                                                    var valid =
                                                                      ref.valid
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        _vm._g(
                                                                          {
                                                                            staticClass:
                                                                              "flex-grow-1",
                                                                            attrs: {
                                                                              label:
                                                                                "Value",
                                                                              "hide-details":
                                                                                "",
                                                                              clearable:
                                                                                "",
                                                                              "error-messages": errors,
                                                                              success: valid
                                                                            },
                                                                            scopedSlots: _vm._u(
                                                                              [
                                                                                {
                                                                                  key:
                                                                                    "append",
                                                                                  fn: function() {
                                                                                    return [
                                                                                      _c(
                                                                                        "v-btn",
                                                                                        {
                                                                                          attrs: {
                                                                                            small:
                                                                                              "",
                                                                                            icon:
                                                                                              ""
                                                                                          },
                                                                                          on: {
                                                                                            click: function(
                                                                                              $event
                                                                                            ) {
                                                                                              on.click(
                                                                                                $event
                                                                                              )
                                                                                              _vm.openFormFieldDatePicker = true
                                                                                            }
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-icon",
                                                                                            {
                                                                                              attrs: {
                                                                                                color:
                                                                                                  "#3F51B5"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                " " +
                                                                                                  _vm._s(
                                                                                                    _vm.mdiCalendar
                                                                                                  ) +
                                                                                                  " "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ]
                                                                                  },
                                                                                  proxy: true
                                                                                }
                                                                              ],
                                                                              null,
                                                                              true
                                                                            ),
                                                                            model: {
                                                                              value:
                                                                                _vm.selectedMatchValue,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.selectedMatchValue = $$v
                                                                              },
                                                                              expression:
                                                                                "selectedMatchValue"
                                                                            }
                                                                          },
                                                                          on
                                                                        )
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          }
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value:
                                                  _vm.openFormFieldDatePicker,
                                                callback: function($$v) {
                                                  _vm.openFormFieldDatePicker = $$v
                                                },
                                                expression:
                                                  "openFormFieldDatePicker"
                                              }
                                            },
                                            [
                                              _vm.openFormFieldDatePicker
                                                ? _c("v-date-picker", {
                                                    directives: [
                                                      {
                                                        name: "click-outside",
                                                        rawName:
                                                          "v-click-outside",
                                                        value:
                                                          _vm.onFormFieldDatePickerClickOutside,
                                                        expression:
                                                          "onFormFieldDatePickerClickOutside"
                                                      }
                                                    ],
                                                    attrs: {
                                                      range:
                                                        _vm.selectedMatchChoice ===
                                                        _vm.MATCH_CHOICE_VALUES
                                                          .RANGE,
                                                      width: 300
                                                    },
                                                    on: {
                                                      "click:date": function(
                                                        $event
                                                      ) {
                                                        return _vm.onFormFieldDateClick()
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedMatchValue,
                                                      callback: function($$v) {
                                                        _vm.selectedMatchValue = $$v
                                                      },
                                                      expression:
                                                        "selectedMatchValue"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm.fieldType ===
                                          _vm.FIELD_TYPES.NUMBER
                                        ? _c(
                                            "validation-provider",
                                            {
                                              attrs: {
                                                rules: {
                                                  required: true,
                                                  regex: /^\d+$/
                                                }
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "flex-grow-1",
                                                attrs: {
                                                  type: "number",
                                                  label: "Value",
                                                  "hide-details": ""
                                                },
                                                model: {
                                                  value: _vm.selectedMatchValue,
                                                  callback: function($$v) {
                                                    _vm.selectedMatchValue = $$v
                                                  },
                                                  expression:
                                                    "selectedMatchValue"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : [
                                            _c(
                                              "validation-provider",
                                              {
                                                attrs: {
                                                  rules: { required: true }
                                                }
                                              },
                                              [
                                                _vm.valueChoices.length > 0
                                                  ? _c("v-autocomplete", {
                                                      staticClass:
                                                        "flex-grow-1",
                                                      attrs: {
                                                        items: _vm.valueChoices,
                                                        "item-text": "label",
                                                        "item-value": "value",
                                                        label: "Value",
                                                        "hide-details": ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selectedMatchValue,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.selectedMatchValue = $$v
                                                        },
                                                        expression:
                                                          "selectedMatchValue"
                                                      }
                                                    })
                                                  : _c("v-text-field", {
                                                      staticClass:
                                                        "flex-grow-1",
                                                      attrs: {
                                                        label: "Value",
                                                        "hide-details": ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selectedMatchValue,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.selectedMatchValue = $$v
                                                        },
                                                        expression:
                                                          "selectedMatchValue"
                                                      }
                                                    })
                                              ],
                                              1
                                            )
                                          ]
                                    ],
                                    2
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        color: "#3F51B5",
                                        disabled: !isFormValid,
                                        id: "addFilter"
                                      },
                                      on: { click: _vm.addToFilter }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          " " + _vm._s(_vm.mdiFilterPlus) + " "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      { staticClass: "d-flex justify-end px-5 py-3" },
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              elevation: "2",
                              color: "#3F51B5",
                              disabled: !_vm.filterChanged,
                              dark: _vm.filterChanged,
                              id: "updateFilterButton"
                            },
                            on: { click: _vm.updateFilter }
                          },
                          [_vm._v(" Update Filter ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }