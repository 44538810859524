var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-wrap gap" },
    _vm._l(_vm.filterChoices, function(f, i) {
      return _c(
        "v-chip",
        {
          key: f.selectedField,
          attrs: { close: "" },
          on: {
            "click:close": function($event) {
              return _vm.$emit("remove-filter", i)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "chip overflow-hidden text-truncate my-0 py-0" },
            [
              _vm._v(" " + _vm._s(f.selectedField) + " "),
              _c("MatchIcon", {
                staticClass: "mx-2",
                attrs: { value: f.selectedMatchChoice }
              }),
              Array.isArray(f.selectedMatchValue)
                ? [_vm._v(" " + _vm._s(f.selectedMatchValue.join(", ")) + " ")]
                : [_vm._v(" " + _vm._s(f.selectedMatchValue) + " ")]
            ],
            2
          )
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }