<template>
  <div class="d-flex flex-wrap gap">
    <v-chip
      v-for="(f, i) of filterChoices"
      :key="f.selectedField"
      close
      @click:close="$emit('remove-filter', i)"
    >
      <div class="chip overflow-hidden text-truncate my-0 py-0">
        {{ f.selectedField }}
        <MatchIcon :value="f.selectedMatchChoice" class="mx-2" />
        <template v-if="Array.isArray(f.selectedMatchValue)">
          {{ f.selectedMatchValue.join(", ") }}
        </template>
        <template v-else>
          {{ f.selectedMatchValue }}
        </template>
      </div>
    </v-chip>
  </div>
</template>

<script>
import MatchIcon from "@/components/list/gis-data-point-filter-dialog/MatchIcon";

export default {
  name: "FilterChoicesDisplay",
  props: {
    filterChoices: Array,
  },
  components: {
    MatchIcon,
  },
};
</script>
