import MATCH_CHOICE_VALUES from "@/constants/matchChoiceValues";
import moment from "moment";

const checkMatch = ({ val, selectedMatchChoice, selectedMatchValue }) => {
  if (selectedMatchChoice === MATCH_CHOICE_VALUES.EQUALS) {
    return val === selectedMatchValue;
  } else if (selectedMatchChoice === MATCH_CHOICE_VALUES.CONTAINS) {
    return val.includes(selectedMatchValue);
  } else if (selectedMatchChoice === MATCH_CHOICE_VALUES.GREATER_THAN) {
    return +val > +selectedMatchValue;
  } else if (
    selectedMatchChoice === MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL
  ) {
    return +val >= +selectedMatchValue;
  } else if (selectedMatchChoice === MATCH_CHOICE_VALUES.LESS_THAN) {
    return +val < +selectedMatchValue;
  } else if (selectedMatchChoice === MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL) {
    return +val <= +selectedMatchValue;
  } else if (selectedMatchChoice === MATCH_CHOICE_VALUES.DOES_NOT_EQUAL) {
    if (typeof val === "number" || typeof selectedMatchValue === "number") {
      return +val !== +selectedMatchValue;
    } else {
      return val !== selectedMatchValue;
    }
  } else if (selectedMatchChoice === MATCH_CHOICE_VALUES.ON_OR_AFTER) {
    return moment(val).isSameOrAfter(selectedMatchValue);
  } else if (selectedMatchChoice === MATCH_CHOICE_VALUES.ON_OR_BEFORE) {
    return moment(val).isSameOrBefore(selectedMatchValue);
  } else if (selectedMatchChoice === MATCH_CHOICE_VALUES.ON_OR_AFTER) {
    return moment(val).isSameOrAfter(selectedMatchValue);
  } else if (selectedMatchChoice === MATCH_CHOICE_VALUES.RANGE) {
    const [startDate, endDate] = selectedMatchValue;
    return moment(val).isBetween(
      startDate,
      moment(endDate, "YYYY-MM-DD").add(24, "hours"),
      null,
      "[]"
    );
  }
  return false;
};

export default checkMatch;
